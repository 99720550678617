import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
import termsImg from '../assets//images/terms-of-service.jpg'

const TermsOfService = () => {
    return (
        <Layout pageTitle="Team">
            <Navbar />
            <PageBanner
                pageTitle="Terms of Service"
                homePageText="Home"
                homePageUrl="/"
                activePageText="Terms of Service"
            />
            <section className="terms-of-service-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="terms-of-service-content">

                                <div>
                                    <h2><span style={{ color: 'rgb(68, 68, 68)' }}>1. Terms of Service</span></h2>
                                    <h3><span style={{ color: 'rgb(68, 68, 68)' }}>1. Terms</span></h3>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>By accessing the website at <a href="https://codejays.com">Code Jays</a> you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</span></p>
                                    <h3><span style={{ color: 'rgb(68, 68, 68)' }}>2. Use License</span></h3>
                                    <ol>
                                        <li><span style={{ color: 'rgb(68, 68, 68)' }}>Permission is granted to temporarily download one copy of the materials (information or software) on Code Jays's website for personal, non-commercial transitory viewing only. This is the grant of a licence, not a transfer of title, and under this licence you may not:</span></li>
                                        <li className="ql-indent-1"><span style={{ color: 'rgb(68, 68, 68)' }}>modify or copy the materials;</span></li>
                                        <li className="ql-indent-1"><span style={{ color: 'rgb(68, 68, 68)' }}>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</span></li>
                                        <li className="ql-indent-1"><span style={{ color: 'rgb(68, 68, 68)' }}>attempt to decompile or reverse engineer any software contained on Code Jays website;</span></li>
                                        <li className="ql-indent-1"><span style={{ color: 'rgb(68, 68, 68)' }}>remove any copyright or other proprietary notations from the materials; or</span></li>
                                        <li className="ql-indent-1"><span style={{ color: 'rgb(68, 68, 68)' }}>transfer the materials to another person or 'mirror' the materials on any other server.</span></li>
                                        <li><span style={{ color: 'rgb(68, 68, 68)' }}>This licence shall automatically terminate if you violate any of these restrictions and may be terminated by Code Jays at any time. Upon terminating your viewing of these materials or upon the termination of this licence, you must destroy any downloaded materials in your possession whether in electronic or printed format.</span></li>
                                    </ol>
                                    <h3><span style={{ color: 'rgb(68, 68, 68)' }}>3. Disclaimer</span></h3>
                                    <ol>
                                        <li><span style={{ color: 'rgb(68, 68, 68)' }}>The materials on Code Jays's website are provided on an 'as is' basis. Code Jays makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</span></li>
                                        <li><span style={{ color: 'rgb(68, 68, 68)' }}>Further, Code Jays does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</span></li>
                                    </ol>
                                    <h3><span style={{ color: 'rgb(68, 68, 68)' }}>4. Limitations</span></h3>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>In no event shall Code Jays or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Code Jays's website, even if Code Jays or a Code Jays authorised representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</span></p>
                                    <h3><span style={{ color: 'rgb(68, 68, 68)' }}>5. Accuracy of materials</span></h3>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>The materials appearing on Code Jays's website could include technical, typographical, or photographic errors. Code Jays does not warrant that any of the materials on its website are accurate, complete or current. Code Jays may make changes to the materials contained on its website at any time without notice. However Code Jays does not make any commitment to update the materials.</span></p>
                                    <h3><span style={{ color: 'rgb(68, 68, 68)' }}>6. Links</span></h3>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>Code Jays has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Code Jays of the site. Use of any such linked website is at the user's own risk.</span></p>
                                    <h3><span style={{ color: 'rgb(68, 68, 68)' }}>7. Modifications</span></h3>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>Code Jays may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</span></p>
                                    <h3><span style={{ color: 'rgb(68, 68, 68)' }}>8. Governing Law</span></h3>
                                    <p><span style={{ color: 'rgb(68, 68, 68)' }}>These terms and conditions are governed by and construed in accordance with the laws of Code Jays and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</span></p>
                                </div>

                            </div>
                        </div>
                        {/*
                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/about-us">
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                Purchase Guide
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                Terms of Service
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="widget widget_recent_courses">
                                    <h3 className="widget-title">Recent Courses</h3>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg1" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$49.00</span>
                                            <h4 className="title usmall"><Link to="#">The Data Science Course 2020: Complete Data Science Bootcamp</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg2" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$59.00</span>
                                            <h4 className="title usmall"><Link to="#">Java Programming MasterclassName for Software Developers</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg3" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$69.00</span>
                                            <h4 className="title usmall"><Link to="#">Deep Learning A-Z™: Hands-On Artificial Neural Networks</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>
                                </div>

                                <div className="widget widget_tag_cloud">
                                    <h3 className="widget-title">Popular Tags</h3>

                                    <div className="tagcloud">
                                        <Link to="#">Business <span className="tag-link-count"> (3)</span></Link>
                                        <Link to="#">Design <span className="tag-link-count"> (3)</span></Link>
                                        <Link to="#">Braike <span className="tag-link-count"> (2)</span></Link>
                                        <Link to="#">Fashion <span className="tag-link-count"> (2)</span></Link>
                                        <Link to="#">Travel <span className="tag-link-count"> (1)</span></Link>
                                        <Link to="#">Smart <span className="tag-link-count"> (1)</span></Link>
                                        <Link to="#">Marketing <span className="tag-link-count"> (1)</span></Link>
                                        <Link to="#">Tips <span className="tag-link-count"> (2)</span></Link>
                                    </div>
                                </div>
                            </aside>
                        </div>
    */}
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default TermsOfService;